import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
    container: {
        padding: "80px 0px 80px 0px",
        display: "flex",
        flexDirection: "row",
        minHeight: "70vh",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            paddingTop: "24px"
        },
    },
    text: {
        textAlign: "left",
        [theme.breakpoints.down("sm")]: {
            textAlign: "center"
        },
    },
    video: {
        boxShadow: "0 24px 64px rgb(2 8 49 / 50%)",
        borderRadius: "4px",
        // position: "relative",
        // left: "100px",
        // height: "400px",
        // width: "600px",
        margin: "0px",
        [theme.breakpoints.down("sm")]: {
            left: "0px",
            marginTop: "24px"
        },
    },
    chrome: {
        // margin: "0px", 
        width: "32px",
        // [theme.breakpoints.down("sm")]: {
        //     margin: "auto"
        // },
    },
    heroContent: {
        justifyContent: "flex-start", 
        alignItems: "flex-start", 
        padding: "0px 48px 0px 24px", 
        minWidth: "500px",
         [theme.breakpoints.down("sm")]: {
             padding: "0px",
             alignItems: "center",
             justifyContent: "center",
             minWidth: "0px"
        },
    },
    button: {
        borderRadius: "8px", 
        width: "100%",
        textAlign: "center",
        padding: "12px",
        [theme.breakpoints.down("sm")]: {
            width: "95%",
            height: "56px",
            padding: "16px"
       },
    }
}));
