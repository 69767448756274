import React, { createContext } from "react";

// Create context
export const GlobalContext = createContext();

// Provider component
export const GlobalProvider = (props) => {
  // // Actions (that will make calls to AppReducer)
  // const setUser = (user) => {
  //   console.log("INSIDE SET USER", user);
  //   // Object being passed into dispatch is "action" being received by AppReducer
  //   dispatch({
  //     type: "SET_USER",
  //     payload: user,
  //   });
  // };

  return (
    <GlobalContext.Provider
      // Need to pass all state variables and dispatch-using functions
      value={props.value}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};
