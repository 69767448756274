import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";
import Image from "../elements/Image";
import Modal from "../elements/Modal";
import useSignInWithGoogle from "../../hooks/signInWithGoogle";
import ReactGA from 'react-ga4';

import useStyles from "./styles";
// import { GlobalContext } from "../../A_context/GlobalState";
import Chrome from "../../assets/icons/other-icons/chrome.png"

import videoSource from "../../assets/videos/demo4.mov"

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  // const context = useContext(GlobalContext);
  const history = useHistory();
  const [videoModalActive, setVideomodalactive] = useState(false);

  const signInWithGoogle = useSignInWithGoogle();

  // const openModal = (e) => {
  //   e.preventDefault();
  //   setVideomodalactive(true);
  // };

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  };

  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  // const innerClasses = classNames(
  //   "hero-inner section-inner",
  //   topDivider && "has-top-divider",
  //   bottomDivider && "has-bottom-divider",
  // );

  const styles = useStyles();

  return (
    <section {...props} className={outerClasses} style={{marginTop: "48px"}}>
      <div className="container-sm" style={{padding: "0px"}}>
        <div className={styles.container}>
          <div className={styles.heroContent}>
            <div className={styles.text}>
              <h1
                className="mt-0 mb-16 reveal-from-bottom"
                data-reveal-delay="200"
              >
                Spaced Repetition<br/>
                {/* <span className="text-color-primary"> */}
                in Notion
                {/* </span> */}
              </h1>
              <div className="container-xs">
                <p
                  className="m-0 mb-32 reveal-from-bottom"
                  data-reveal-delay="400"
                  style={{fontSize: "18px"}}
                >
                  Learn without wasting your time
                </p>
                <div className="reveal-from-bottom" data-reveal-delay="600">
                  <Button
                    tag="a"
                    color="primary"
                    onClick={async () => {
                      // await signInWithGoogle();
                      // history.push("/app/mydecks");
                      ReactGA.event({
                        category: 'User',
                        action: 'Clicked "Install for Free" button'
                      });
                    }}
                    href="https://chrome.google.com/webstore/detail/spaced-repetition-in-noti/gloldpghbjmemjddgapkonmeiggbgihb?hl=en&authuser=4"
                    className={styles.button}
                  >
                    Install Completely for Free
                  </Button>
                </div>
              </div>
              <div style={{display: "flex", flexDirection: "column", marginTop: "32px"}}>
                <p style={{fontSize: "14px", marginBottom: "10px"}}>Works with</p>
                <img src={Chrome} className={styles.chrome}/>
              </div>
            </div>
          </div>
          <div
            className="hero-figure reveal-from-bottom illustration-element-01"
            data-reveal-value="20px"
            data-reveal-delay="800"
          >
            {/* <a
              data-video="https://player.vimeo.com/video/174002812"
              href="#0"
              aria-controls="video-modal"
              onClick={openModal}
            > */}
            {/* <Image
              className={styles.image}
              src={require("./../../assets/images/hero-image.png")}
              alt="Hero"
            /> */}
            <video className={styles.video} playsInline autoPlay loop muted >
              <source src={videoSource} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            {/* </a> */}
          </div>
          {/* <Modal
            id="video-modal"
            show={videoModalActive}
            handleClose={closeModal}
            video="https://player.vimeo.com/video/174002812"
            videoTag="iframe"
          /> */}
        </div>
      </div>
    </section>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
