import React, {
  useRef,
  useEffect,
  Suspense,
  useState,
  useLayoutEffect,
} from "react";
import {
  useLocation,
  Switch,
  Route,
  useHistory,
  Redirect,
} from "react-router-dom";
import AppRoute from "./utils/AppRoute";
import ScrollReveal from "./utils/ScrollReveal";
// import ReactGA from "react-ga";

// Context
import { GlobalProvider } from "./A_context/GlobalState";

// Styles
import "./App.css";
import { StylesProvider } from "@material-ui/core/styles";

// Spinner
import { CircularProgress } from "@material-ui/core";

// Landing Page
import Home from "./views/Home";
import LayoutDefault from "./layouts/LayoutDefault";

// Imports
import useStyles from "./styles";
import classnames from "classnames";
import { useLayoutState } from "./A_context/LayoutContext";

// components
import Header from "./components/A_Header";
import Sidebar from "./components/A_Sidebar/Sidebar";

// Google analytics set up
import ReactGA from "react-ga4"
const TRACKING_ID = "307566938"; 
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

// const Layout = React.lazy(() => import("./layouts/Layout"));

// UNCOMMENT BELOW BLOCK WHEN GOOGLE ANALYTICS IS SET UP
// Initialize Google Analytics
// ReactGA.initialize(process.env.REACT_APP_GA_CODE);
// const trackPage = (page) => {
//   ReactGA.set({ page });
//   ReactGA.pageview(page);
// };

const App = () => {
  //////////////////////
  ///////// Global Context
  const user = undefined;

  // Initial state
  const initialState = {
    user,
  };

  const [globalState, setGlobalState] = useState(initialState);

  /////////
  //////////////////////

  //////////////////////
  ////////// Check if user is logged in

  useLayoutEffect(() => {
    const asyncFunction = async () => {
      const loggedInUser = localStorage.getItem("user");
      if (loggedInUser) {
        const foundUser = JSON.parse(loggedInUser);
        // Call backend database to retrieve user accessToken and check if it matches one in local storage
        const response = await fetch(
          `https://repetify.herokuapp.com/api/users/${foundUser.googleId}`
        );
        const user = await response.json();
        // If yes, allow to login, if no, do nothing
        // if (user.accessToken === foundUser.accessToken) {
          setGlobalState((prevGlobalState) => {
            return {
              ...prevGlobalState,
              user: foundUser,
            };
          });
        // }
      }
    };
    asyncFunction();
  }, []);

  /////////
  //////////////////////

  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    document.body.classList.add("is-loaded");
    childRef.current.init();
    // UNCOMMENT LINE BELOW WHEN GOOGLE ANALYTICS IS SET UP
    // const page = location.pathname;
    // trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  ////////////////////

  const MyDecks = React.lazy(() => import("./A_pages/my-decks/MyDecks"));
  const Settings = React.lazy(() => import("./A_pages/menu-pages/Settings"));
  const Subscription = React.lazy(() =>
    import("./A_pages/menu-pages/Subscription")
  );
  const ComingSoon = React.lazy(() =>
    import("./A_pages/coming-soon/ComingSoon")
  );
  const DeckPage = React.lazy(() => import("./A_pages/deck-page/DeckPage"));
  const CreateDeck = React.lazy(() => import("./components/CreateDeck"));
  const SignInPage = React.lazy(() => import("./components/SignInPage"))

  // User agreements
  const Privacy = React.lazy(() => import("./A_pages/user-agreements/Privacy"));
  const Terms = React.lazy(() => import("./A_pages/user-agreements/Terms"));

  const classes = useStyles();
  const history = useHistory;
  const layoutState = useLayoutState();

  return (
    <GlobalProvider value={{ globalState, setGlobalState }}>
      <StylesProvider>
        <ScrollReveal
          ref={childRef}
          children={() => (
            <Suspense
              fallback={
                <CircularProgress color="primary" className="spinner" />
              }
            >
              <Switch>
                  <AppRoute
                    exact
                    path="/"
                    component={Home}
                    layout={LayoutDefault}
                  />
                  <Route path="/privacy" component={Privacy} />
                  <Route path="/terms" component={Terms} />
                  <Route path="/create-deck" component={CreateDeck} />
                  <Route path="/sign-in" component={SignInPage} />
                  {/* This always triggers because for a split-second, globalState.user is not defined */}
                  {/* <Route path="/*">
                    <Redirect to="/" />
                  </Route> */}
                  {globalState.user && (
                    <div className={classes.root}>
                      <Header history={history} />
                      {/* Commenting out sidebar */}
                      {/* <Sidebar /> */}
                      <div
                        className={classnames(classes.content, {
                          [classes.contentShift]: layoutState.isSidebarOpened,
                        })}
                      >
                        <div className={classes.fakeToolbar} />
                        <Route path="/" exact>
                          <Redirect to="/app/mydecks" />
                        </Route>
                        <Route path="/app/mydecks" exact component={MyDecks} />
                        <Route
                          path="/app/mydecks/:deckTitle/:deckId"
                          component={DeckPage}
                        />
                        <Route path="/app/settings" component={Settings} />
                        {/* <Route
                          path="/app/subscription"
                          component={Subscription}
                        /> */}
                        <Route path="/app/shared" component={ComingSoon} />
                        <Route path="/app/community" component={ComingSoon} />
                        {/* <Route path="/*">
                          <Redirect to="/app/mydecks" />
                        </Route> */}
                      </div>
                    </div>
                  )}
                  {/* Temporarily redirecting to home page until I can figure out why users need to sign in again when clicking "study as flashcards" */}
                  {!localStorage.getItem("user") && (
                    <Route path="/app/mydecks">
                      <Redirect to="/"/>
                    </Route>
                  )}
              </Switch>
            </Suspense>
          )}
        />
      </StylesProvider>
    </GlobalProvider>
  );
};

export default App;
