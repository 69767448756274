import { useContext } from "react";
import { auth, googleProvider } from "../firebase";
import { GlobalContext } from "../A_context/GlobalState";

export default function useSignInWithGoogle() {
  const context = useContext(GlobalContext);
  return async (phoneNumber = "") => {
    try {
      const res = await auth.signInWithPopup(googleProvider);
      console.log(res);
      const {
        id: googleId,
        name: fullName,
        given_name: firstName,
        family_name: lastName,
        picture,
        email,
        locale,
      } = res.additionalUserInfo.profile;
      const { accessToken } = res.credential;
      const user = {
        googleId,
        fullName,
        firstName,
        lastName,
        picture,
        email,
        locale,
        accessToken,
      };

      // save phone number in DB only if it was provided from sign-in page
      if (phoneNumber != "") {
        user.phoneNumber = phoneNumber
      }

      // Need to JSON.stringify post request body
      const response = await fetch("https://repetify.herokuapp.com/api/login", {
        method: "post",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(user),
      });
      // Need to await response.json() to convert readable streame into json data
      const userFromDatabase = await response.json();
      context.setGlobalState((prevContext) => {
        return {
          ...prevContext,
          user: userFromDatabase,
        };
      });
      // Need to JSON.stringify before going into local storage, and JSON.parse when coming out
      localStorage.setItem("user", JSON.stringify(userFromDatabase));
    } catch (error) {
      console.log(error.message);
    }
  };
}
