import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
// import Input from "../elements/Input";
import Button from "../elements/Button";
import useSignInWithGoogle from "../../hooks/signInWithGoogle";
// import { GlobalContext } from "../../A_context/GlobalState";
import useStyles from "./styles"

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool,
};

const defaultProps = {
  ...SectionProps.defaults,
  split: false,
};

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {
  // const context = useContext(GlobalContext);
  const history = useHistory();
  const styles = useStyles()

  const signInWithGoogle = useSignInWithGoogle();

  const outerClasses = classNames(
    "cta section center-content-mobile",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "cta-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider",
    split && "cta-split"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div className="cta-slogan">
            <h3 className="m-0">
              Ready to save time?<br />Try Repetify free, no-strings-attached
            </h3>
          </div>
          <div className="cta-action">
            {/* <Input
              id="newsletter"
              type="email"
              label="Subscribe"
              labelHidden
              hasIcon="right"
              placeholder="Your best email"
            >
              <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z"
                  fill="#376DF9"
                />
              </svg>
            </Input> */}
            <Button
              tag="a"
              color="tertiary"
              wideMobile
              href="https://chrome.google.com/webstore/detail/spaced-repetition-in-noti/gloldpghbjmemjddgapkonmeiggbgihb?hl=en&authuser=4"
              className={styles.button}
              style={{width: "215px"}}
            >
              Install Completely for Free
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
