import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router";
import Image from "../elements/Image";

import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Button as MaterialButton,
} from "@material-ui/core";
import { Menu as MenuIcon, Payment, Settings } from "@material-ui/icons";
import classNames from "classnames";

// styles
import useStyles from "./styles";
import { useTheme } from "@material-ui/styles";

// components
import { Typography } from "../A_Wrappers";
import Button from "../../components/elements/Button"

import repetifyLogo from "../../assets/icons/repetify-icons/Logo.svg"

// context
import { GlobalContext } from "../../A_context/GlobalState";
import {
  useLayoutDispatch,
  toggleSidebar,
} from "../../A_context/LayoutContext";

import { ReactComponent as LOGO } from "../../assets/icons/repetify-icons/Logo.svg";

export default function Header(props) {
  const context = useContext(GlobalContext);
  var classes = useStyles();
  const theme = useTheme();

  // global
  var layoutDispatch = useLayoutDispatch();

  // local
  var [profileMenu, setProfileMenu] = useState(null);
  const history = useHistory();

  //////////////////////
  ///////// Profile menu pages

  const settingsHandler = () => {
    setProfileMenu(null);
    history.push("/app/settings");
  };

  const subscriptionRedirect = () => {
    setProfileMenu(null);
    history.push("/app/subscription");
  };

  /////////
  //////////////////////

  //////////////////////
  ///////// Sign out handler

  const signOutHandler = () => {
    // Set user state to undefined
    context.setGlobalState((prevGlobalState) => {
      return {
        ...prevGlobalState,
        user: undefined,
      };
    });
    // Remove user from local storage
    localStorage.removeItem("user");
    // Redirect to home page
    history.push("/");
  };

  /////////
  //////////////////////


  ////////////////////////////////////
  ////////////////// CHARGEBEE STUFF
  ////////////////////////////////////
  // useEffect(() => {
  //   // Initialize chargebee drop-in script checkout with customer information

  //   // exit if customer not signed in
  //   if (!context.globalState.user) return;

  //   const cbInstance = window.Chargebee.getInstance();
  //   const cart = cbInstance.getCart();
  //   const customer = {
  //     first_name: context.globalState.user.firstName,
  //     last_name: context.globalState.user.lastName,
  //     email: context.globalState.user.email,
  //     billing_address: {
  //       first_name: context.globalState.user.firstName,
  //       last_name: context.globalState.user.lastName,
  //     },
  //   };
  //   cart.setCustomer(customer);
  //   window.Chargebee.registerAgain();
  // }, []);

  // useEffect(() => {
  //   if (context.globalState.isPremium) return; // exit if already ran once
  //   if (!context.globalState.user) return; // exit if no user signed in

  //   const checkIfPremium = async () => {
  //     // Check if user is premium
  //     const premiumResponse = await fetch(
  //       `https://repetify.herokuapp.com/api/chargebee/customer?user_email=${context.globalState.user.email}`
  //     );
  //     const premium = await premiumResponse.json();
  //     context.setGlobalState((prevState) => {
  //       return {
  //         ...prevState,
  //         isPremium: premium.isPremium,
  //       };
  //     });
  //   };
  //   checkIfPremium();
  // }, [])

  return (
    <AppBar position="fixed" className={classes.appBar} elevation={0}>
      <Toolbar className={classes.toolbar} style={{ background: "#FCFDFE" }}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButtonSandwich,
            classes.headerMenuButtonCollapse
          )}
        >
          {/* {layoutState.isSidebarOpened ? ( */}
          {/* <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : ( */}
          <MenuIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
            style={{ color: "#020831" }}
          />
          {/* )} */}
        </IconButton>
        {/* <Typography variant="h6" weight="medium" className={classes.logotype}>
          React Material Admin
        </Typography> */}
        {/* PUT REPETIFY LOGO HERE */}
        <LOGO
          className={classes.unselectable}
          style={{ cursor: "pointer", width: "180px" }}
          onClick={() => {
            if (context.globalState.user) {
              history.push("/app/mydecks")
            } else {
              history.push("/")
            }
          }}
        />
        <div className={classes.grow} />
        {/* PUT SETTINGS ICON HERE */}
        {/* <Button component={Link} href="https://flatlogic.com/templates/react-material-admin-full" variant={"outlined"} color={"secondary"} className={classes.purchaseBtn}>Unlock full version</Button> */}
        {/* <div
          className={classNames(classes.search, {
            [classes.searchFocused]: isSearchOpen,
          })}
        >
          <div
            className={classNames(classes.searchIcon, {
              [classes.searchIconOpened]: isSearchOpen,
            })}
            onClick={() => setSearchOpen(!isSearchOpen)}
          >
            <SearchIcon classes={{ root: classes.headerIcon }} />
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
          />
        </div>
        <IconButton
          color="inherit"
          aria-haspopup="true"
          aria-controls="mail-menu"
          onClick={e => {
            setNotificationsMenu(e.currentTarget);
            setIsNotificationsUnread(false);
          }}
          className={classes.headerMenuButton}
        >
          <Badge
            badgeContent={isNotificationsUnread ? notifications.length : null}
            color="warning"
          >
            <NotificationsIcon classes={{ root: classes.headerIcon }} />
          </Badge>
        </IconButton>
        <IconButton
          color="inherit"
          aria-haspopup="true"
          aria-controls="mail-menu"
          onClick={e => {
            setMailMenu(e.currentTarget);
            setIsMailsUnread(false);
          }}
          className={classes.headerMenuButton}
        >
          <Badge
            badgeContent={isMailsUnread ? messages.length : null}
            color="secondary"
          >
            <MailIcon classes={{ root: classes.headerIcon }} />
          </Badge>
        </IconButton> */}

        {/* PREMIUM button */}
        {/* COMMENTED OUT */}
        {/* <MaterialButton
          variant="outlined"
          color="primary"
          style={{
            color: theme.palette.primary.main,
            fontSize: "14px",
            border:
              context.globalState.isPremium !== "active" &&
              "3px solid " + theme.palette.primary.main,
            borderRadius: "8px",
            background: "#EFFFFFFF"
          }}
          className={classes.premiumButton}
          href={
            context.globalState.isPremium === "active" ||
            context.globalState.isPremium === "not_renewing"
              ? undefined
              : "javascript:void(0)"
          }
          data-cb-type = "checkout"  data-cb-item-0 = "Repetify-Yearly-Subscription-USD-Yearly"  data-cb-item-0-quantity = "1"
          onClick={
            context.globalState.isPremium === "active" ||
            context.globalState.isPremium === "not_renewing"
              ? subscriptionRedirect
              : undefined
          }
        >
          Subscribe
        </MaterialButton> */}

        {/* SUBSCRIPTION STUFF */}
        {/* {<Button
            tag="a"
            color="primary"
            className={context.globalState.isPremium !== "no" ? classNames(classes.button, classes.hidden) : classes.button}
            data-cb-type="checkout"
            data-cb-item-0="Repetify-Yearly-Subscription-USD-Yearly"
            data-cb-item-0-quantity="1"
            href="#" 
            onClick={(e) => {
              e.preventDefault()
            }}
          >

          {context.globalState.isPremium === "non_renewing"
            ? "Re-activate Subscription"
            : "Subscribe"}
          </Button>} */}

        {/* HERE'S PROFILE ICON */}
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={(e) => setProfileMenu(e.currentTarget)}
          style={{ background: "#FFFFFF" }}
        >
          {/* <AccountIcon classes={{ root: classes.headerIcon }} /> */}
          {context.globalState.user && <Image
            className={classes.unselectable}
            src={context.globalState.user.picture}
            width={32}
            alt=""
            style={{ borderRadius: "16px" }}
          />}
        </IconButton>
        {/* <Menu
          id="mail-menu"
          open={Boolean(mailMenu)}
          anchorEl={mailMenu}
          onClose={() => setMailMenu(null)}
          MenuListProps={{ className: classes.headerMenuList }}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium">
              New Messages
            </Typography>
            <Typography
              className={classes.profileMenuLink}
              component="a"
              color="secondary"
            >
              {messages.length} New Messages
            </Typography>
          </div>
          {messages.map(message => (
            <MenuItem key={message.id} className={classes.messageNotification}>
              <div className={classes.messageNotificationSide}>
                <UserAvatar color={message.variant} name={message.name} />
                <Typography size="sm" color="text" colorBrightness="secondary">
                  {message.time}
                </Typography>
              </div>
              <div
                className={classNames(
                  classes.messageNotificationSide,
                  classes.messageNotificationBodySide,
                )}
              >
                <Typography weight="medium" gutterBottom>
                  {message.name}
                </Typography>
                <Typography color="text" colorBrightness="secondary">
                  {message.message}
                </Typography>
              </div>
            </MenuItem>
          ))}
          <Fab
            variant="extended"
            color="primary"
            aria-label="Add"
            className={classes.sendMessageButton}
          >
            Send New Message
            <SendIcon className={classes.sendButtonIcon} />
          </Fab>
        </Menu>
        <Menu
          id="notifications-menu"
          open={Boolean(notificationsMenu)}
          anchorEl={notificationsMenu}
          onClose={() => setNotificationsMenu(null)}
          className={classes.headerMenu}
          disableAutoFocusItem
        >
          {notifications.map(notification => (
            <MenuItem
              key={notification.id}
              onClick={() => setNotificationsMenu(null)}
              className={classes.headerMenuItem}
            >
              <Notification {...notification} typographyVariant="inherit" />
            </MenuItem>
          ))}
        </Menu> */}
        {/* HERE'S PROFILE MENU */}
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="subtitle2" weight="bold">
              {context.globalState.user && context.globalState.user.fullName}
            </Typography>
          </div>
          {/* <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem
            )}
            onClick={subscriptionRedirect}
          >
            <Payment className={classes.profileMenuIcon} fontSize="small" />
            <Typography variant="caption">
              Subscription {"&"} Billing
            </Typography>
          </MenuItem> */}
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem
            )}
            onClick={settingsHandler}
          >
            <Settings className={classes.profileMenuIcon} fontSize="small" />
            <Typography variant="caption">Settings</Typography>
          </MenuItem>
          <div className={classes.profileMenuUser}>
            <MaterialButton
              tag="a"
              variant="outlined"
              color="primary"
              style={{ fontSize: "12px", borderRadius: "8px"}}
              onClick={signOutHandler}
            >
              Sign Out
            </MaterialButton>
          </div>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
