import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: "AIzaSyCq1GGR061pHrFV2WMdw_ClXhHXaoVLC8Y",
    authDomain: "repetify-development.firebaseapp.com",
    projectId: "repetify-development",
    storageBucket: "repetify-development.appspot.com",
    messagingSenderId: "756579737605",
    appId: "1:756579737605:web:d79eecf5cd59d38c517c6c",
    measurementId: "G-NWBG97L863",
  });
}

export const googleProvider = new firebase.auth.GoogleAuthProvider();

export const auth = firebase.auth();

// export const signInWithGoogle = async (setGlobalState) => {
//   try {
//     const res = await auth.signInWithPopup(googleProvider);
//     console.log(res);
//     const {
//       email,
//       family_name: familyName,
//       given_name: firstName,
//       id: googleId,
//       locale,
//       name: fullName,
//       picture,
//     } = res.additionalUserInfo.profile;
//     const { accessToken } = res.credential;
//     console.log(email, fullName);
//     setGlobalState((prevContext) => {
//       return {
//         ...prevContext,
//         user: {
//           email,
//           familyName,
//           firstName,
//           googleId,
//           locale,
//           fullName,
//           picture,
//         },
//       };
//     });
//   } catch (error) {
//     console.log(error.message);
//   }
// };
