import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ReactComponent as LOGO } from "../../assets/icons/repetify-icons/Icon.svg";
import FooterNav from "./partials/FooterNav";
// import FooterSocial from "./partials/FooterSocial";

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool,
};

const defaultProps = {
  topOuterDivider: false,
  topDivider: false,
};

const Footer = ({ className, topOuterDivider, topDivider, ...props }) => {
  const classes = classNames(
    "site-footer center-content-mobile",
    topOuterDivider && "has-top-divider",
    className
  );

  return (
    <footer {...props} className={classes}>
      <div className="container">
        <div
          className={classNames(
            "site-footer-inner",
            topDivider && "has-top-divider"
          )}
        >
          <div className="footer-top space-between text-xxs">
            <LOGO />
            {/* <FooterSocial /> */}
          </div>
          <div className="footer-bottom space-between text-xxs invert-order-desktop">
            Need help?
            <br />
            Email us at yoineedsomehelp@repetify.com
            <br />
            We’ll answer in 24 hours or less.
            <br />
            <br />
            <FooterNav />
            <div className="footer-copyright">
              Founded with{" "}
              <span role="img" aria-label="love">
                ❤️
              </span>{" "}
              by{" "}
              <a href="https://www.linkedin.com/in/youssef-gendy-7754aa189/">
                Youssef Gendy
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
